@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,semibold,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:regular,semibold,bold,italic&subset=latin,latin-ext');
/* -----Global------*/

:root {
    --main-black: #303030;
    --main-red: #FF5A60;
    --main-green: #11BF9F;
    --main-gray: #777777;
    --main-outline: #DADADA;
    --light-outline: #E8E8E8;
    --light-background: #F4F4F4;
    --light-green: #9FE5D8;
}
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
}
body, button {
    font-family: Source Sans Pro, sans-serif;
}
.mainContent {
    float: left;
    width: 66%;
}
.wrapper {
    width: 76%;
    padding: 0 12%;
}
.flexWrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between
}
a {
    color: rgba(255, 90, 96, 0.795);
    text-decoration: none;
}



/* -----buttons------*/
a.btnPrimary {
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    background-color: var(--main-red);
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
}
a.btnPrimary {
    padding: 12px 28px;
}
.btnPrimary, .btnSecondary {
    background: none;
    font-weight: bold;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    cursor: pointer;
    display: inline-block;
}
.btnPrimary:hover {
    border-color: #FF7B7F;
    color: #FF7B7F;
}
.btnSecondary {
    border: 1px solid var(--main-outline);
    padding: 8px 16px;
    border-radius: 5px;
    font-weight: bold;
}
.btnSecondary:hover {
    color: var(--main-green);
    border-color: var(--main-green);
}
.btnTertiary {
    font-weight: 600;
}
.btnTertiary:hover {
    color: #58D2BB;
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
}

/* -----header/nav----*/
nav {
    display: inline-block;
    width: 100%;
    margin-bottom: 16px;
}
nav a {
    color: var(--main-black);
    text-decoration: none;
    cursor: pointer;
}
.logo {
    color: var(--main-green);
    margin-left: 3px; /* correct slight overhang */
    font-family: "Libre Baskerville";
    font-weight: 700;
    font-size: 20px;
    /* line-height: 80px; */
}
.logo img {
    width: 100px;
    height: 100px;
    float: left;
    
}
.logo div {
    float: left;
    margin-top: 30px;
    margin-left: 30px;
}
.logo ul {
    float: left;
}
.logo ul li {
    font-size: 20px;
    margin-left: 10px;
}
nav ul {
    float: right;
    list-style: none;
    padding-left: 0;
}
nav ul li {
    margin-left: 0;
    float: left;
    font-size: 14px;
    font-weight: 600;
    line-height: 56px;
    padding: 0 15px;
    text-align: center;
}
nav .leftNav {
    margin-left: 0;
}
nav ul li a:hover {
    color: var(--main-green);
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
}

/* -----landing page----*/
header {
    margin-bottom: 56px;
}
h1 {
    font-size: 40px;
    font-weight: 600;
}
header h1 {
    margin: 112px 0 40px 0;
    width: 50%;
}
header .btnTertiary {
    margin-left: 16px;
}
.secondHeader h1 {
    margin-bottom: 40px;
}
.hero {
    width: 50%;
    position: absolute;
    right: 32px;
    top: 112px;
}
.newJobs {
    margin-top: 160px;
}
.newArticles {
    margin-top: 120px;
}
.mini {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
.mini a {
    width: 27%;
    border-radius: 10px;
    padding: 24px 24px;
    color: var(--main-black);
    background-color: 	#eefbf8;
}
.mini a:hover {
    /*add box shadow */
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
}
.title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 14px;
}
.lightTitle {
    margin-top: 14px;
    color: var(--main-gray);
}
.seeMore {
    font-weight: 600;
    float: right;
    margin-top: 8px;
}
.secondHeader {
    margin: 120px 0;
}
.secondHeader img {
    width: 100%;
}
.column {
    float: left;
    width: 50%;
}
.secondHeader .right {
    margin: 100px 0 auto 64px;
}


/* -----jobs list----*/
#searchInput {
    background-image: url('searchIcon.png'); /* Add a search icon to input */
    background-size: 16px;
    background-position: 8px 10px; /* Position the search icon */
    background-repeat: no-repeat; /* Do not repeat the icon image */
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    width: 100%;
    font-size: 16px; /* Increase font-size */
    padding: 8px 20px 8px 32px; /* Add some padding */
    border: 1px solid var(--main-outline); /* Add a grey border */
    border-radius: 5px;
    margin-bottom: 16px; /* Add some space below the input */
}
#searchInput:focus {
    outline: none;
    border-color: white;
    box-shadow: 0 0 0 2px var(--main-green);
    transition: all .05s ease;
    -webkit-transition: all .05s ease;
}
.jobFilters {
    margin-bottom: 32px;
}
.jobFilters .filter-row {
    display: flex;
    flex-wrap: wrap;
}
.jobFilters .filter-row > div {
    flex: 1;
    margin-right: 20px;
}
@media only screen and (max-width: 768px){
    .filter-row {
        flex-direction: column;
    }
    .filter-row > div {
        margin-right: 0;
        margin-bottom: 20px;
    }
}
.list {
    padding: 0;
    list-style: none;
}
.list li a {
    display: block;
    text-decoration: none;
    border-bottom: 1px solid var(--light-outline);
    padding-bottom: 24px;
    margin-bottom: 24px;
}
.list li a:hover .jobTitle {
    color: #6E6E6E;
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
}
.list .companyLogo img {
    width: 56px;
    margin-top: 6px;
    margin-left: 12px;
    float: right;
}
.jobTitle, h3 {
    font-size: 22px;
    font-weight: 600;
    color: var(--main-black);
}
.jobFacts {
    font-size: 16px;
    color: var(--main-black);
    margin-top: 8px;
}
.jobFacts span {
    margin-right: 16px;
}
.jobFacts img {
    height: 12px;
    margin-right: 3px;
}
.jobExcerpt {
    color: var(--main-gray);
    margin: 10px 0 3px 0;
    line-height: 22px;
    height: 4.2em;
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
}




/* -----sidebar----*/
.sideBar {
    float: left;
    width: 26%;
    padding-left: 8%;
    word-wrap: break-word;
}
.sideBar h1 {
    font-size: 20px;
    line-height: 0;
    margin-bottom: 24px;
    font-weight: 600;
}
.sideBar p {
    line-height: 22px;
    margin-bottom: 24px;
}
.mobile.sideBar {
    display: none;
}



/* -----job information page----*/
.jobDetails {
}
.jobDetails .jobTitle {
    font-size: 32px;
    margin-top: 0px;
    margin-bottom: 24px;
}
.jobDetails .companyLogo img {
    height: 40px;
    margin-top: 0px;
    margin-left: 8px;
    border-radius: 3px;
    float: right;
}
.briefcaseIcon {
    padding-left: 2px;
    padding-right: 3px;
}
.jobDetails a {
    font-weight: 600;
    color: var(--main-green);
}
.jobDetails .jobFacts {
    border-bottom: 1px solid var(--light-outline);
    padding-bottom: 24px;
    margin-bottom: 24px;
}
.jobDetails .jobFacts p {
    margin-top: 0;
    margin-bottom: 8px;
}
.leftCol, .rightCol {
    float: left;
    width: 50%;
}
.clear {
    clear: left;
}
.greenBackground {
    margin-top: 142px;
    background-color: var(--light-green);
    border-radius: 5px;
    padding: 24px 32px;
}
.greenBackground a {
    color: black;
}
.greenBackground p {
    margin-bottom: 0;
}
.jobDetails .sideBar p {
    margin-bottom: 0;
}
.jobDescription p {
    margin-top: 8px;
}



/* -----advice articles-----*/
.articles img {
    float: right;
    width: 200px;
    height: 136px;
    padding-left: 16px;
    margin: auto 0 auto 0;
    object-fit: cover;
}

/* -----forms (filters, post job)-----*/
#root__title { /* formats titles of forms */
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 16px;
}
textarea, select, .postJob input {
    width: 50%;
    padding: 8px 8px;
    margin-bottom: 16px;
    margin-top: 4px;
    display: block;
    border: 1px solid var(--main-outline);
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 14px;
}
select {
    width: 144px;
}
input[type=checkbox] {
    display: inline-block;
    width: 16px;
    margin-bottom: 0px;
}
input[type=file] {
    margin-top: -12px;   /* not sure why there's extra space below "company logo" */
}
label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
}
.checkboxContainer { /* adds space after last checkbox in Post Job form */
    margin-bottom: 16px;
}
.upload {

}
input[type=checkbox] + label {
    font-weight: 100;
}
fieldset { /* remove border of react json form */
    border: 0;
    padding: 0;
}
legend { /* remove indent of react json form */
    padding: 0;
}


/* -----payment-----*/
.__PrivateStripeElement {  /* controls width of Stripe card element */
    width: 50%;
}
.StripeElement {  /* adds space above and below Stripe card element*/
    margin-top: 4px;
    margin-bottom: 16px;
}


/* -----footer-----*/
.footer {
    margin-top: 60px;
    margin-bottom: 32px;
    color: var(--main-gray);
}



/* -----reponsive layouts-----*/
.mainContent {
    margin: 0 auto;
}
@media only screen and (max-width: 1000px) {
    .mainContent {
        width: 100%;
    }    
    header h1 {
        width: 100%;
    }
    .hero {
        display: none;
    }
    .desktop.sideBar {
        display: none;
    }
    .mobile.sideBar, .jobDetails .sideBar {
        display: inline-block;
        width: 100%;
        float: none;
        padding: 0 0 24px 0;
        margin: 0;
    }
    .greenBackground {
        margin-top: 16px;
    }
    .mini a {
        width: 100%;
        margin-top: 8px;
    }
    .column {
        float: none;
        width: 100%;
    }
    .secondHeader .right {
        margin-left: 0px;
    }
    textarea, select, .postJob input, .__PrivateStripeElement {
        width: 70%;
    }

}
@media only screen and (max-width: 768px){
    .mainContent {
    }
    nav ul {
        float: left;
        margin-bottom: -10px;
    }
    nav ul li {
        line-height: 16px;
        width: 40%;
        margin-bottom: 20px;
    }
    .wrapper {
        width: 90%;
        padding: 0 5%;
    }
    .articles img {
        float: right;
        width: 90px;
        height: 58px;
        padding-left: 16px;
        margin: auto 0 auto 0;
        object-fit: cover;
    }
    .leftCol, .rightCol {
        float: left;
        width: 100%;
    }
    textarea, select, .postJob input, .__PrivateStripeElement {
        width: 100%;
    }

}



